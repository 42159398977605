<template>
    <div>
        <div style="position: fixed;top: 0;left: 0;width: 100%;background: #fff;z-index: 9;">
            <nav>
                <div class="nav_bar">
                    <!-- <div class="logo"> -->
                    <!-- <img width="100px" height="100px" src="../../assets/logo.png" alt="立优教育" /> -->
                    <img class="logo" :src="this.url.imgUrl + logoImg" alt="立优教育" />
                    <!-- </div> -->
                    <ul class="nav_middle">
                        <li @click="showLine">
                            <router-link :to="'/'" :class="{ txt_color: !is_pageid }">首页</router-link>
                        </li>
                        <li>
                            <router-link :to="'/course/' + 2" :class="{ txt_color: is_pageid == 2 }">课程</router-link>
                        </li>
                        <li>
                            <router-link :to="'/audition/' + 3" :class="{ txt_color: is_pageid == 3 }">试听</router-link>
                        </li>
                        <li>
                            <router-link :to="'/teachers/' + 4" :class="{ txt_color: is_pageid == 4 }">师资</router-link>
                        </li>
                        <li>
                            <router-link :to="'/information/' + 5" :class="{ txt_color: is_pageid == 5 }">资讯
                            </router-link>
                        </li>
                        <li class="hot">
                            <router-link :to="'/modelpaper'" :class="{ txt_color: is_pageid == 10 }">模考大赛</router-link>
                        </li>
                        <li>
                            <router-link :to="'/personal/' + 8" :class="{ txt_color: is_pageid == 8 }">学习中心
                            </router-link>
                        </li>
                        <li>
                            <span :class="{ txt_color: is_pageid == 9 }" @click="goTopic">题库</span>
                        </li>
                        <li class="hoverli">
                            APP下载
                            <div class="hover">
                                <a :href="`${url.baseUrl}/help/downlod.html`" target="_blank">立优课堂APP</a>
                                <a :href="`${url.baseUrl}/help/adownlod.html`" target="_blank">安全工程师刷题宝</a>
                            </div>
                        </li>
                        <!-- <div class="line_down"></div> -->
                    </ul>
                    <div class="btn">
                        <!-- 头像 -->
                        <img class="head_img" src="../../assets/img/touxiang.png" alt="立优教育" />
                        <button type="button" round @click="goLogin" v-if="!loginBtn" class="login_btn">登录 | 注册</button>
                        <div class="sign_out" v-if="loginBtn">
                            <div>{{ nickname }}</div>
                            <button type="button" round @click="signOut">退出</button>
                        </div>

                        <!-- </a> -->
                    </div>
                </div>
            </nav>
            <!-- <div v-if="maskStatus == true">盒子</div> -->
        </div>
        <el-dialog title="提示" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%"
            :before-close="handleClose">
            <span class="msg_box">
                <img src="../../assets/img/msg.png" alt="立优教育" />您确定要退出登录吗?
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleClose">确 定</el-button>
            </span>
        </el-dialog>
        <div class="box">
            <div style="height:40vw"></div>
            <div class="body">
                <div class="tit">
                    <el-dropdown @command="handleCommand" placement="bottom-start">
                        <span class="el-dropdown-link">
                            {{ tit.name }}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu style="width:300px;padding:0 10px;" class="menuu" slot="dropdown">
                            <el-dropdown-item v-for="item in course_list" :key="item.id + 'i'" :command="item">{{
                                    item.name
                            }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <ul>
                        <li v-for="item in chapter_category_listarr" :class="isact == item.id ? 'active' : ''"
                            :key="item.id + 'a'" @click="categoryClick(item)">{{ item.name }}</li>
                    </ul>
                </div>
                <div class="cardlist" v-if="list.length > 0">
                    <div class="card" v-for="item in list" :key="item.id + 'b'" @click="goOption(item)">
                        <h2>{{ item.papers_title }}</h2>
                        <p>
                            <img src="../../assets/img/time@2x.png" alt />
                            开考时间：{{ item.starttime }}
                        </p>
                        <p>
                            <img src="../../assets/img/yuyue@2x.png" alt />
                            <span>{{ item.sign_num }}</span>人已预约
                        </p>
                        <div :class="item.status == 0 ? 'buton back' : 'buton'">{{ item.status == 0 ? '立即预约' :
                                item.is_finish == 0 ? '继续考试' : '开始考试'
                        }}</div>
                    </div>

                    <!-- <div class="card">
                        <h2>
                            2022年一级建造师《建设工程项目管理》模拟
                            试卷（二）
                        </h2>
                        <p>
                            <img src="../../assets/img/time@2x.png" alt />开考时间：2022-03-07 14:00
                        </p>
                        <p>
                            <img src="../../assets/img/yuyue@2x.png" alt />
                            <span>2375</span>人已预约
                        </p>
                        <div class="buton">继续考试</div>
                    </div>
                    <div class="card">
                        <h2>
                            2022年一级建造师《建设工程项目管理》模拟
                            试卷（二）
                        </h2>
                        <p>
                            <img src="../../assets/img/time@2x.png" alt />开考时间：2022-03-07 14:00
                        </p>
                        <p>
                            <img src="../../assets/img/yuyue@2x.png" alt />
                            <span>2375</span>人已预约
                        </p>
                        <div class="buton">继续考试</div>
                    </div>
                    <div class="card">
                        <h2>
                            2022年一级建造师《建设工程项目管理》模拟
                            试卷（二）
                        </h2>
                        <p>
                            <img src="../../assets/img/time@2x.png" alt />开考时间：2022-03-07 14:00
                        </p>
                        <p>
                            <img src="../../assets/img/yuyue@2x.png" alt />
                            <span>2375</span>人已预约
                        </p>
                        <div class="buton">继续考试</div>
                    </div>
                    <div class="card">
                        <h2>
                            2022年一级建造师《建设工程项目管理》模拟
                            试卷（二）
                        </h2>
                        <p>
                            <img src="../../assets/img/time@2x.png" alt />开考时间：2022-03-07 14:00
                        </p>
                        <p>
                            <img src="../../assets/img/yuyue@2x.png" alt />
                            <span>2375</span>人已预约
                        </p>
                        <div class="buton back">继续考试</div>
                    </div>
                    <div class="card">
                        <h2>
                            2022年一级建造师《建设工程项目管理》模拟
                            试卷（二）
                        </h2>
                        <p>
                            <img src="../../assets/img/time@2x.png" alt />开考时间：2022-03-07 14:00
                        </p>
                        <p>
                            <img src="../../assets/img/yuyue@2x.png" alt />
                            <span>2375</span>人已预约
                        </p>
                        <div class="buton back">继续考试</div>
                    </div>-->
                </div>
                <div v-else class="nolist">
                    <img src="../../assets/img/kong.png" alt />
                    <p>暂时没有更多试题～</p>
                    <div @click="nolistclick" class="buton">去题库看看</div>
                </div>
            </div>
            <div class="botom">
                <div class="l">
                    <img src="https://res-qd.liyouedu.cn/resources/client/erweima/xiazai.png" alt />
                </div>
                <div class="z">
                    <h2>扫码下载立优课堂APP，随时随地参加模考</h2>
                    <p>安装APP >> 进入首页 >> 参加模考大赛</p>
                </div>
                <div class="bg">
                    <img src="https://res-qd.liyouedu.cn/resources/mocks/mokao@2x.png" alt />
                </div>
            </div>
        </div>

        <el-dialog class="sure" :visible.sync="dialogVisibles" :show-close="false" width="30%">
            <img src="https://res-qd.liyouedu.cn/resources/mocks/dialog1.png" alt @click="dialogVisibles = false" />
        </el-dialog>
    </div>
</template>

<script>
import { getLocalTime } from '@/utils/validate.js'
import api from "@/api/api";
export default {
    name: 'ModelPaper',
    data() {
        return {
            dialogVisibles: false,
            list: [],
            isact: 37,
            chapter_category_listarr: [],
            course_list: [],
            tit: "",
            logoImg: "/assets/img/logo1.png", //logo
            is_pageid: "", // 当前页面id
            auditionInfo_page: "",
            isToken: 0,
            dialogVisible: false,
            btnText: "登录 | 注册",
            loginBtn: true,
            nickname: "", //用户昵称
        }
    },
    methods: {
        nolistclick() {
            this.$router.push({ path: '/simulation/9/4' });
        },
        //去做题
        goOption(el) {
            if (el.status == 0) {
                this.dialogVisibles = true
            } else {
                // console.log(el);
                // 开始做题之前先清除本地做题数据
                sessionStorage.removeItem("user");
                this.$router.push({
                    name: "modelPaperdetail",
                    params: {
                        nav_id: 9,
                        chapter_id: this.tit.chapter_category,
                        chapter_name: this.tit.name,
                        paper_id: el.id,
                        paper_name: el.papers_title,
                        router_name: "模拟试题",
                    },
                });
            }
        },
        //列表
        mock_list(id) {
            api.mock_list({ three_chaptercate_id: id, chapter_category: this.tit.chapter_category }).then(res => {
                if (res.data.code == 1) {
                    this.list = res.data.data.list
                    this.list.forEach(e => e.starttime = getLocalTime(e.starttime))
                } else {
                    this.$message.error(res.data.msg)
                }

            })
        },
        //科目
        categoryClick(val) {
            this.isact = val.id
            this.mock_list(val.id)
        },
        chapter_category_list(val) {
            api.chapter_category_list({ pid: val, chapter_category: val }).then(res => {
                let a = res.data.data.list
                a.unshift({ id: 0, name: '全部' })
                this.chapter_category_listarr = a
                this.isact = a[0].id
                this.mock_list(a[0].id)
            })
        },
        handleCommand(command) {
            this.tit = command
            localStorage.setItem('mock_paper', JSON.stringify(command))
            this.chapter_category_list(command.chapter_category)
        },
        getNickname() {
            var _this = this;
            var callback = {
                errorHandler: {
                    status_401: (output) => {
                        localStorage.removeItem("token");
                        _this.loginBtn = false;
                    },
                },
            };
            api.center(null, callback).then((res) => {
                let routename = this.$route.name
                if (parseInt(res.data.code) == 1) {
                    this.agreement_array = res.data.data.agreement
                    _this.loginBtn = true;
                    _this.nickname = res.data.data.nickname;
                    if (res.data.data.agreement.agreement_list.toString() != '' && (routename == 'Index' || routename == 'Personal' || routename == 'Chapter')) {
                        this.isshow = true
                    } else {

                    }
                }
            });
            // }
        },
        // 去登录页
        goLogin() {
            // window.location.href = "/pc/login.html";
            // this.$router.push({ name: "Login" });
            let routeData = this.$router.resolve({ path: "/login" });
            window.open(routeData.href, "_blank");
        },
        // 退出
        signOut() {
            this.dialogVisible = true;
            this.maskStatus = true;
        },
        handleClose() {
            this.dialogVisible = false;
            this.$message.success({ duration: 1500, message: "退出成功!" });
            localStorage.removeItem("token");
            this.$router.push({ name: "Login" });
        },
        // 显示线
        showLine() {
            this.is_pageid = this.$route.params.is_pageid;
        },
        // 去题库中心
        goTopic() {
            this.$router.push({
                name: "Chapter",
                params: { is_pageid: 9, select_id: 1 },
            });
        },
    },
    created() {
        this.getNickname();
        var token = localStorage.getItem("token");
        if (token == null) {
            this.loginBtn = false;
        } else {
            this.loginBtn = true;
        }
        api.category_list().then(res => {
            let a = []
            res.data.data.list.forEach(e => a = [...a, ...e.children])
            this.course_list = a
            let mock_paper = localStorage.getItem('mock_paper')
            let two_chaptercate_id = this.$route.query.two_chaptercate_id
            console.log(two_chaptercate_id)
            if (two_chaptercate_id) {
                let c = a.find(e => e.id == two_chaptercate_id)
                if (c) {
                    this.tit = c
                    this.chapter_category_list(c.chapter_category)
                } else {
                    this.tit = a[1]
                    this.chapter_category_list(a[1].chapter_category)
                }
            } else {
                if (mock_paper) {
                    mock_paper = JSON.parse(mock_paper)
                    this.tit = mock_paper
                    this.chapter_category_list(mock_paper.chapter_category)
                } else {
                    this.tit = a[1]
                    this.chapter_category_list(a[1].chapter_category)
                }
            }
        })
        //当前页面id
        this.is_pageid = 10;
        // this.auditionInfo_page = localStorage.getItem("natActive");
    },
}
</script>

<style lang="scss" scoped>
.box {
    margin-top: 50px;
    overflow: hidden;
    background: #f88544 url("https://res-qd.liyouedu.cn/resources/mocks/bg.jpg") no-repeat;
    background-size: 100%;
}

.body {
    width: 1400px;
    margin: 0 auto;
    background: #fff;
    border-radius: 12px;
}

::v-deep .tit {
    display: flex;
    align-items: center;
    padding: 30px 36px;
    border-bottom: 2px solid #f3f3f3;

    ul {
        display: flex;
        align-items: center;

        li {
            margin-left: 35px;
            font-size: 20px;
            font-weight: 400;
            color: #424242;
            cursor: pointer;
        }

        .active {
            position: relative;
            color: #de2a17;
        }

        .active::after {
            content: "";
            display: block;
            width: 30px;
            position: absolute;
            bottom: -32px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 2px;
            background: #de2a17;
            height: 4px;
        }
    }

    .el-dropdown-link {
        cursor: pointer;
    }

    .el-dropdown {
        width: 240px;
        font-size: 25px;
        font-family: DFPSongW12-GB;
        font-weight: 400;
        color: #2b2b2b;
        position: relative;
    }

    .el-dropdown::after {
        content: "";
        display: block;
        width: 2px;
        height: 26px;
        background: linear-gradient(0deg,
                #ffffff 0%,
                #c8c8c8 47%,
                #ffffff 100%);
        border-radius: 1px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}

::v-deep .el-dropdown-menu__item {
    font-size: 15px;
    font-family: DFPSongW7-GB;
    font-weight: 400;
    color: #666666;
    line-height: 52px;
}

.cardlist {
    padding: 0 35px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card:hover {
        transform: scale(1.05);
    }

    .card {
        transition: all 0.3s;
        margin-top: 30px;
        cursor: pointer;
        position: relative;
        width: 650px;
        box-sizing: border-box;
        padding: 40px 30px;
        border-radius: 10px;
        border: 1px solid #f5f5f5;
        background: #ffffff;
        box-shadow: 0px 3px 8px 0px rgba(32, 6, 0, 0.08);

        h2 {
            width: 100%;
            font-size: 18px;
            font-family: DFPSongW12-GB;
            font-weight: 400;
            color: #424242;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 28px;
        }

        p {
            font-size: 14px;
            font-family: DFPSongW7-GB;
            font-weight: 400;
            color: #666666;
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            img {
                width: 18px;
                margin-right: 8px;
            }

            span {
                color: #de2a17;
            }
        }

        .buton {
            position: absolute;
            bottom: 40px;
            right: 31px;
            width: 150px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            font-size: 18px;
            font-family: DFPSongW12-GB;
            font-weight: 400;
            color: #ffffff;
            border-radius: 23px;
            background: linear-gradient(90deg, #fd6013 0%, #e62700 99%);
        }

        .back {
            background: linear-gradient(90deg,
                    #ff9c00 0%,
                    #ff5900 99%) !important;
        }
    }
}

.botom {
    .bg {
        width: 369px;
        height: 230px;
        position: absolute;
        right: 0;
        bottom: 0;

        img {
            width: 100%;
            height: 100%;
        }
    }

    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    padding: 30px 37px;
    display: flex;
    align-items: center;
    width: 1400px;
    margin: 20px auto 110px;
    border-radius: 12px;
    background: #ffffff;

    .l {
        width: 220px;
        height: 220px;
        background: url("https://res-qd.liyouedu.cn/resources/mocks/erweima@2x.png") no-repeat center;
        background-size: 100% 100%;

        img {
            width: 192px;
            height: 192px;
            margin: 14px;
        }
    }

    .z {
        margin-left: 30px;

        h2 {
            font-size: 28px;
            font-family: DFPSongW12-GB;
            font-weight: 400;
            color: #424242;
            margin-bottom: 17px;
        }

        p {
            font-size: 22px;
            font-family: DFPSongW7-GB;
            font-weight: 400;
            color: #999999;
        }
    }
}

a {
    color: #000;
}

nav {
    width: 100%;
    height: 50px;
    // box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.06);
}

nav .nav_bar {
    width: 1196px;
    height: 100%;
    background: #fff;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 168px;
    // height: 39px;
}

nav .nav_middle {
    width: 666px;
    display: flex;
    justify-content: space-around;
    /* background: pink; */
}

nav .nav_middle li {
    font-size: 15px;
    font-family: PingFang SC;
    color: #222222;
    line-height: 52px;
    text-align: center;
    cursor: pointer;
}

nav .nav_middle li a:hover {
    color: #3e7eff;
    font-weight: 600;
}

nav .nav_middle li span:hover {
    color: #3e7eff;
}

.txt_color {
    color: #3e7eff !important;
    font-weight: 600;
}

.nav_line {
    width: 22px;
    height: 3px;
    background: #3e7eff;
    border-radius: 1px;
    margin: 8px auto 0;
}

.nav_middle a {
    /* position: relative; */
    cursor: pointer;
    color: #333;
    display: block;
    margin: 0 auto;
}

.txt_color {
    color: #3e7eff !important;
    font-weight: 600;
}

.nav_line {
    width: 22px;
    height: 3px;
    background: #3e7eff;
    border-radius: 1px;
    margin: 8px auto 0;
}

.nav_middle a {
    /* position: relative; */
    cursor: pointer;
    color: #333;
    display: block;
    margin: 0 auto;
}

.msg_box {
    display: flex;
    font-size: 20px;
}

.msg_box img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.line_down {
    width: 22px;
    height: 3px;
    background: #3e7eff;
    border-radius: 1px;
    margin: -7px auto 0;
}

.btn {
    display: flex;
    width: 142px;
    align-items: center;
}

.btn .head_img {
    width: 34px;
    height: 34px;
    cursor: pointer;
}

.btn .login_btn {
    width: 100px;
    height: 32px;
    border: 2px solid #3e7eff;
    border-radius: 16px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #3e7eff;
    background: #fff;
    margin-left: 6px;
    cursor: pointer;
}

.sign_out {
    margin-left: 8px;
    cursor: pointer;
}

.sign_out div {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #3e7eff;
    margin-bottom: -4px;
}

.sign_out button {
    width: 70px;
    height: 18px;
    border: 1px solid #999999;
    border-radius: 8px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    background: #fff;
    line-height: 16px;
    margin-top: -5px;
    cursor: pointer;
}

.hoverli {
    position: relative;
}

.hoverli:hover {
    color: #3e7eff;
}

.hoverli:hover .hover {
    height: 88px;
}

.hoverli .hover {
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
    width: 200%;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    z-index: 10;
    right: -50%;
    line-height: 1;
}

.hoverli .hover a {
    width: 100%;
    font-size: 14px;
    position: relative;
    display: block;
    text-align: center;
    padding: 15px 0px;
    background: #fff;
}

.hoverli .hover a::after {
    content: "";
    display: block;
    width: 80%;
    height: 1px;
    background: #eee;
    position: absolute;
    bottom: 0;
    right: 10%;
}

::v-deep .sure {
    .el-dialog {
        background: transparent;
        box-shadow: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        padding: 0;
        text-align: center;

        img {
            width: 396px;
            height: 465px;
            cursor: pointer;
        }
    }
}

.nolist {
    margin: 0 auto;
    padding: 85px 0 80px;
    text-align: center;

    img {
        width: 286px;
        margin-bottom: 30px;
    }

    p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-bottom: 16px;
    }

    .buton {
        background: linear-gradient(90deg, #fd6013 0%, #e62700 99%);
        width: 200px;
        height: 46px;
        line-height: 46px;
        border-radius: 23px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin: 0 auto;
        cursor: pointer;
    }
}

.hot {
    position: relative;
}

.hot::after {
    content: "";
    display: block;
    width: 16px;
    height: 19px;
    background: url(../../assets/img/hot2.png) no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    top: 2px;
    right: -12px;
}
</style>